import { Snackbar } from '@/components/atoms/Snackbar';
import { Content } from '@/components/organisms/Content';
import { Menu } from '@/components/organisms/Menu';
import { StyledFlexCenterExpanded } from '@/components/styled';
import { GoogleAnalytics } from '@/components/templates/GoogleAnalytics';
import { ConfirmErrorDialog } from '@/features/components/modals/ConfirmErrorDialog';
import { useError } from '@/hooks/global/useError';
import { useUser } from '@auth0/nextjs-auth0/client';
import { AxiosError } from 'axios';
import { memo, useMemo } from 'react';
import { Auth } from './Auth';
import { StyledBody, StyledFrame } from './styled';

type Props = {
  children: React.ReactNode;
};

/**
 * JSDoc
 * @see Sugekaeのレイアウトコンポーネント
 * @see アプリのレイアウトを定義している
 * @param {Props} props
 * @returns {JSX.Element}
 */
export const Layout = memo(({ children }: Props) => {
  const { user, error: userError, isLoading: isAuth0Loading } = useUser();
  const { error: generalError, isError, setIsError, generatingStatus } = useError();

  const errorMessage = useMemo(() => {
    if (generalError instanceof AxiosError) {
      return generalError?.response?.data?.message_jp;
    } else {
      return generalError?.message || generatingStatus || undefined;
    }
  }, [
    generalError,
    generatingStatus,
  ])

  const errorCode = useMemo(() => {
    if (generalError instanceof AxiosError) {
      return generalError?.response?.status || generatingStatus || undefined;
    } else {
      return generatingStatus || undefined;
    }
  }, [
    generalError,
    generatingStatus,
  ])

  return (
    <>
      <GoogleAnalytics />
      <Auth user={user} error={userError} isAuth0Loading={isAuth0Loading}>
        <StyledBody>
          <Snackbar />
          <StyledFrame>
            <StyledFlexCenterExpanded>
              <Menu />
              <Content>{children}</Content>
            </StyledFlexCenterExpanded>
          </StyledFrame>
        </StyledBody>
        {isError && (
          <ConfirmErrorDialog
            setIsError={setIsError}
            errorCode={errorCode}
            errorMessage={errorMessage}
          />
        )}
      </Auth>
    </>
  );
});
